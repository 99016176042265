import showdown from 'showdown';
class Utils {

  formatDate(date) {
    return new Date(date).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
GetdayName(date) {
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dateinput= new Date(date);
  const dayIndex=dateinput.getDay();
  return dayNames[dayIndex];
}
getDateWithDayName(date) {
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dateinput = new Date(date);
  const dayIndex = dateinput.getDay();
  return `${dayNames[dayIndex]} ${this.byodformatDate(date)}`;
}
  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  getBytesInMB(bytes, decimals = 2) {
    const dm = decimals < 0 ? 0 : decimals;
    return parseFloat(bytes / 1024 ** 2).toFixed(dm);
  }
  getBytesFromGB(gbSize) {
    return gbSize * Math.pow(1024, 3);
  }
  getGBFromMB(fileSize, decimals) {
    const dm = decimals < 0 ? 0 : decimals;
    return parseFloat(fileSize / 1024).toFixed(dm);
  }

  getGBFormat(size) {
    return Number(size).toLocaleString('fullwide', {useGrouping:false});
  }
  getFileExtension(fileName) {
    var re = /(?:\.([^.]+))?$/;
    return re.exec(fileName)[1];
  }
  isContainSpecialCharacters(name) {
   // var regex = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/;
   var regex = /[`!@#$%^*+\=\[\]{};':"\\|,<>\/?~]/;
    return regex.test(name);
  }

  //ddmmyyyhhmmss
  customformattedDate(date) {
  let datePart = [
    date.getDate(),
    date.getMonth() + 1
    // ,date.getFullYear()
  ].map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0")).join("");
  let timePart = [
    date.getHours(),
    //date.getMinutes(),
    date.getSeconds()
  ].map((n, i) => n.toString().padStart(2, "0")).join("");
  return datePart + timePart;
}
// as per figma
byodformatDate(date) {
  const fDate = new Date(date);

  let hours = fDate.getHours();
  let minutes = fDate.getMinutes();
  let newformat = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${("00" + hours).slice(-2)}:${(
    "00" + minutes
  ).slice(-2)} ${newformat}`;
}

 /**
   * Get the HTML equivalent to Markdown text
   * @param {*} markdownText - Markdown text
   * @returns HTML equivalent to Markdown text
   */
 markdownToHtml = (markdownText) => {
  var converter = new showdown.Converter({ literalMidWordUnderscores: true });
  return converter.makeHtml(markdownText);
}
}
export default Utils;
